@import "./assets/css/include/_variables_default.scss";
.block-reassurances {
  padding: 25px 0;
  @include media-breakpoint-up(lg){
    padding: 10px 0 65px 0;
  }
  .block-reassurances__item {
    margin-bottom: 40px;
    @include media-breakpoint-up(lg){
      margin-bottom: 0;
    }
    i {
      font-size: 5rem;
      color: $primary-color;
    }
    h3 {
      font-family: $font-family-bold;
    }
  }
}