@import "./assets/css/include/_variables_default.scss";
/* GLIDE */
.glide__arrows {
  .glide__arrow--right{
    width: 50px;
    height: 50px;
    border : none;
    padding: 10px;
    border-radius: $border-radius-base;
    box-shadow: none;
    right: 0;
    color: $secondary-color;
    @media screen and (min-width: 600px) {
      transform: translate(130%, -50%);
    }
    /*&:hover {
      background-color: $primary-color;
    }*/
  }
  .glide__arrow--left {
    width: 50px;
    height: 50px;
    border : none;
    padding: 10px;
    border-radius: $border-radius-base;
    box-shadow: none;
    right: 0;
    color: $secondary-color;
    @media screen and (min-width: 600px) {
      transform: translate(-200%, -50%);
    }
    &:hover {
      //background-color: $primary-color;
    }
  }
  i {
    font-size: 6rem;
  }
}

/* GLIDE MAIN TITLE - positionnement des fleches */
/*.glide-main-title {
  .glide__arrow--left {
    left: 0;
    transform: translate(20%, -50%);
    @include media-breakpoint-up(lg) {
      left: 0;
      transform: translate(60%, -50%);
    }
  }
  .glide__arrow--right {
    right: 0;
    transform: translate(-20%, -50%);
    @include media-breakpoint-up(lg) {
      right: 0;
      transform: translate(-55%, -50%);
    }
  }
}*/

/* Permet d'avoir la meme height (ex.: Avis client) */
.glide__slide {
  height: inherit;
}

.glide__bullets {
  bottom: -30px;
  .glide__bullet {
    width: 12px;
    height: 12px;
    border: 2px solid $tertiary-color;
    background-color: $tertiary-color;
    box-shadow: none;
    margin-right: 1rem;
    &:last-of-type {
      margin-right: 0;
    }
    @include media-breakpoint-up(sm) {
      margin-right: 3rem;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .glide__bullet--active, .glide__bullet:focus, .glide__bullet:hover {
    background-color: $secondary-color;
    border: 2px solid $secondary-color;
  }
}