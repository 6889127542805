@import "./assets/css/include/_variables_default.scss";
.block-selections {
  padding: 25px 0;
  @include media-breakpoint-up(lg){
    padding: 55px 0;
  }
  .block-selections__item {
    a {
      padding: 0;
      height: 100%;
      figure {
        margin-bottom: 2rem;
        img {
          margin-bottom: 15px;
          border-radius: 20px;
        }
        i {
          font-size: 3rem;
          margin-bottom: 10px;
          @include media-breakpoint-up(lg){
            font-size: 4rem;
          }
        }
        span {
          line-height: 1;
        }
        span.regular {
          font-family: $font-family-regular;
          margin-top: 5px;
        }
      }
    }
  }
}