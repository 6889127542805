@import "./assets/css/include/_variables_default.scss";
.block-slider-publicitaire {
  .block-slider-publicitaire__title {
    font-family: $font-family-bold;
    line-height: 2.4rem;
    @include media-breakpoint-up(lg) {
      font-size: 4.2rem;
      line-height: 3.4rem;
    }
  }
  p {
    font-family: $font-family-regular;
    font-size: 1.7rem;
    line-height: 1.4;
    margin-bottom: 20px;
    @include media-breakpoint-up(lg) {
      font-size: 2.4rem;
      margin-bottom: 40px;
    }
  }

  .glide__content {
    padding-right: 10px;
    @include media-breakpoint-up(lg) {
      padding-right: 150px;
    }
  }

  .glide__slides {
    margin-bottom: 0;
  }
  li.glide__slide {
    position: relative;
    margin-bottom: 0;
    padding: 0;
    &:before {
      content: '';
    }
  }
}

/* GLIDE - positionnement des fleches */
.block-slider-publicitaire {
  @include media-breakpoint-down(sm) {
    padding-bottom: 50px;
  }
  .glide__arrows {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
    }
    .glide__arrow--left {
      left: 0;
      transform: translate(20%, -50%);
      &:hover {
        color: $primary-color!important;
      }
      @include media-breakpoint-up(lg) {
        left: 0;
        transform: translate(60%, -50%);
      }
    }
    .glide__arrow--right {
      right: 0;
      transform: translate(-20%, -50%);
      &:hover {
        color: $primary-color!important;
      }
      @include media-breakpoint-up(lg) {
        right: 0;
        transform: translate(-55%, -50%);
      }
    }
  }
  .glide__bullets {
    display: block;
    bottom: -4rem;
    @include media-breakpoint-up(lg) {
      display: none;
    }
    .glide__bullet--active, .glide__bullet:active, .glide__bullet:focus {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }
}